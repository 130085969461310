<template>
  <b-card>
    <validation-observer ref="validationRules">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('NEW_PASSWORD')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('NEW_PASSWORD')"
                vid="Password"
                rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('RETYPE_NEW_PASSWORD')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('RETYPE_NEW_PASSWORD')"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="onSubmit()"
            >
              {{ $t('BUTTON.SAVE_CHANGES') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('BUTTON.RESET') }}
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions } from 'vuex'
// eslint-disable-next-line no-unused-vars
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    saveOrUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
      errorMessage,
    } = mixinAlert()
    return {
      successMessage,
      showErrorMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('user', ['resetPassword']),
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          const payload = {
            Email: this.userData.email,
            newPassword: this.newPasswordValue,
            confirmPassword: this.newPasswordValue,
          }
          if (payload) {
            this.resetPassword(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.USER_UPDATED'))
              }
            }).catch(error => {
              if (error.response && error.response.status === 400) {
                this.errorMessage(error.response.data.message)
              } else {
                this.showErrorMessage()
              }
            })
          }
        }
      })
    },
  },
}
</script>
