<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            src="@/assets/images/profile/user-uploads/player.jpg"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
    </b-media>
    <!--/ media -->
    <validation-observer ref="validationRules">
      <!-- form -->
      <b-form
        class="mt-2"
        @reset.prevent="resetForm()"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('FIRST_NAME')"
              label-for="id-firstname"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('FIRST_NAME')"
                rules="required"
              >
                <b-form-input
                  v-model="userData.firstName"
                  maxlength="45"
                  :placeholder="$t('FIRST_NAME')"
                  name="id-firstname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('LAST_NAME')"
              label-for="id-lastname"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('LAST_NAME')"
                rules="required"
              >
                <b-form-input
                  v-model="userData.lastName"
                  maxlength="45"
                  name="id-lastName"
                  :placeholder="$t('LAST_NAME')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('EMAIL_ADDRESS')"
              label-for="id-e-mail"
            >
              <b-form-input
                v-model="userData.email"
                maxlength="60"
                name="id-email"
                readonly
                :placeholder="$t('EMAIL_ADDRESS')"
              />

            </b-form-group>
          </b-col>
          <!-- phone -->
          <b-col md="6">
            <b-form-group
              label-for="id-phone"
              :label="$t('PHONE_NUMBER')"
            >
              <b-form-input
                id="id-phone"
                v-model="userData.phoneNumber"
                maxlength="45"
                placeholder="08-121 316 16"
              />
            </b-form-group>
          </b-col>
          <!-- Language -->
          <b-col cols="6">
            <b-form-group
              :label="$t('LANGUAGE')"
              label-for="id-language"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('LANGUAGE')"
                rules="required"
              >
                <v-select
                  id="id-language"
                  v-model="language"
                  :placeholder="$t('LANGUAGE')"
                  label="name"
                  class="flex-grow-1"
                  :clearable="false"
                  :options="LANGUAGES"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- bio -->
          <b-col cols="6">
            <b-form-group
              :label="$t('BIO')"
              label-for="bio-area"
            >
              <b-form-textarea
                id="bio-area"
                v-model="userData.aboutMe"
                maxlength="200"
                rows="4"
                :placeholder="$t('YOUR_BIO_DATA_HERE')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="onSubmit()"
            >
              {{ $t('BUTTON.SAVE_CHANGES') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t('BUTTON.RESET') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow,
  BCol, BCard, BMedia, BMediaAside, BLink, BImg, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import vSelect from 'vue-select'
import constants from '@/constants/static.json'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BLink,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    saveOrUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      profileFile: null,
      required,
      language: '',
    }
  },
  watch: {
    userData: {
      handler() {
        if (this.userData) {
          this.language = this.LANGUAGES.find(i => i.id === this.userData.language)
        }
      },
    },
  },
  methods: {
    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.userData))
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.userData.language = this.language.id
          this.$i18n.locale = this.language.id
          this.saveOrUpdate(this.userData)
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { LANGUAGES } = constants

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      LANGUAGES,
    }
  },
}
</script>
